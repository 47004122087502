<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="participantGroups"
    item-value="id"
    :item-text="item => (item.header ? item.header : item.name)"
    clearable
    multiple
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} <br /> </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ParticipantGroupsField",
  props: {
    value: Array,
    status: { type: String, required: false }
  },
  data: () => ({
    participantGroups: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    let filters = {};
    filters.isEffective = this.status;

    ApiService.post("/api/participantGroups/search?size=500&sort=category,ASC&sort=name,ASC", filters).then(
      ({ data }) => {
        this.participantGroups = data.content;
        let noCategory = { header: "No category" };
        let last =
          this.participantGroups && this.participantGroups.length > 0 ? this.participantGroups[0].category : "";
        if (!last) {
          this.participantGroups.unshift(noCategory);
        } else {
          this.participantGroups.unshift({ header: last });
        }
        for (var i = 0; i < this.participantGroups.length; i++) {
          if (last != this.participantGroups[i].category) {
            last = this.participantGroups[i].category;
            let newone = { header: last };
            this.participantGroups.splice(i, 0, newone);
          }
        }
      }
    );
  }
};
</script>
