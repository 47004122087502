var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Survey - "+_vm._s(_vm.survey.name))]),_c('v-spacer'),(_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","disabled":!_vm.valid},on:{"click":function($event){_vm.onSubmit();
            _vm.isEditing = false;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,4256454278)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Save")))])]):_vm._e(),(!_vm.isEditing && _vm.tab != 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","disabled":!_vm.valid,"loading":_vm.isBusy},on:{"click":function($event){_vm.isEditing = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,243795356)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Edit")))])]):_vm._e(),(_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(!_vm.isNew)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","disabled":!_vm.valid},on:{"click":function($event){_vm.fetchData();
            _vm.isEditing = false;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}],null,false,3066643508)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Cancel")))])]):_vm._e(),(!_vm.isEditing && _vm.tab == 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","disabled":_vm.loading || _vm.isGeneratingCSVReport},on:{"click":function($event){return _vm.$refs.answers.generateCSVReport()}}},'v-btn',attrs,false),on),[(!_vm.isGeneratingCSVReport)?_c('v-icon',[_vm._v("mdi-download")]):_vm._e(),(_vm.isGeneratingCSVReport)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)]}}],null,false,3392208413)},[_c('span',[_vm._v("Export Report")])]):_vm._e()],1),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Details")]),(!_vm.isNew)?_c('v-tab',[_vm._v("Audience")]):_vm._e(),(!_vm.isNew)?_c('v-tab',[_vm._v("Questions")]):_vm._e(),(!_vm.isNew)?_c('v-tab',[_vm._v("Answers")]):_vm._e(),_c('v-tab-item',{attrs:{"eager":""}},[_c('ApiError',{attrs:{"errors":_vm.errors}}),_c('SurveyDetails',{ref:"details",attrs:{"disabled":!_vm.isEditing,"id":this.$route.params.id},on:{"reload":function($event){return _vm.$router.push({ name: 'cprSurvey', params: { id: $event } })}}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('SurveyAudience',{ref:"audience",attrs:{"disabled":!_vm.isEditing,"id":this.$route.params.id,"showParticipantGroups":false}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('SurveyQuestions',{ref:"questions",attrs:{"disabled":!_vm.isEditing,"id":this.$route.params.id}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-card',[_c('SurveyAnswers',{ref:"answers",attrs:{"id":this.$route.params.id,"fileNameStart":_vm.survey.name},on:{"generatingCSV":function($event){_vm.isGeneratingCSVReport = true},"finishedGeneratingCSV":function($event){_vm.isGeneratingCSVReport = false}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }