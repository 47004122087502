<template>
  <v-text-field :value="value" @input="onInput" @change="onChange" v-bind="$attrs" v-on="$listeners"></v-text-field>
</template>
<script>
export default {
  name: "KeyField",
  props: {
    value: String
  },
  components: {},
  data: () => ({}),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    onChange(obj) {
      this.$emit("change", obj);
    }
  }
};
</script>
