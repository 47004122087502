<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="6">
          <OrganizationTypesField v-model="audience.organizationTypes" label="Organization Types" :disabled="disabled">
          </OrganizationTypesField
        ></v-col>
        <v-col cols="6">
          <OrganizationGroupsField
            v-model="audience.organizationGroups"
            label="Organization Groups"
            :disabled="disabled"
          >
          </OrganizationGroupsField
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <ParticipantTypesField v-model="audience.participantTypes" label="Participant Types" :disabled="disabled">
          </ParticipantTypesField
        ></v-col>
        <v-col cols="6" v-if="showParticipantGroups">
          <ParticipantGroupsField v-model="audience.participantGroups" label="Participant Groups" :disabled="disabled">
          </ParticipantGroupsField
        ></v-col>
      </v-row>

      <v-row v-if="!disabled">
        <v-toolbar flat>
          <v-toolbar-title>Eligible Participants</v-toolbar-title>
        </v-toolbar>
        <v-col cols="12">
          <v-data-table
            :items="eligibleParticipants"
            :headers="headers"
            :server-items-length="total"
            :options.sync="options"
          >
            <template v-slot:item.organizationName="{ item }">
              {{ item.organization ? item.organization.name : "" }}
            </template>
            <template v-slot:item.organizationType="{ item }">
              {{ item.organization ? item.organization.organizationType.name : "" }}
            </template>
            <template v-slot:item.organizationGroups="{ item }">
              {{ item.organizationGroups ? item.organizationGroups.map(og => og.name).join(", ") : "" }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ParticipantGroupsField from "../fields/ParticipantGroupsField.vue";
import ParticipantTypesField from "../fields/ParticipantTypesField.vue";
import OrganizationGroupsField from "../fields/OrganizationGroupsField.vue";
import OrganizationTypesField from "../fields/OrganizationTypesField.vue";
import ApiService from "../../services/api.service";
import { mapGetters } from "vuex";

export default {
  components: { OrganizationTypesField, OrganizationGroupsField, ParticipantTypesField, ParticipantGroupsField },
  name: "SurveyAudience",
  props: {
    value: Object,
    id: {
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showParticipantGroups: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    surveyQuestionTypes: [],
    errors: {},
    audience: {},
    eligibleParticipants: [],
    total: 0,
    options: {
      itemsPerPage: 10,
      page: 1,
      sortBy: ["participantKey"],
      sortDesc: [true]
    },
    loading: false
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      let promises = [];
      promises.push(
        ApiService.get("/api/surveys/" + this.id + "/participantTypes").then(({ data }) =>
          this.$set(this.audience, "participantTypes", data._embedded.participantTypes)
        )
      );
      promises.push(
        ApiService.get("/api/surveys/" + this.id + "/participantGroups").then(({ data }) =>
          this.$set(this.audience, "participantGroups", data._embedded.participantGroups)
        )
      );
      promises.push(
        ApiService.get("/api/surveys/" + this.id + "/organizationTypes").then(({ data }) =>
          this.$set(this.audience, "organizationTypes", data._embedded.organizationTypes)
        )
      );
      promises.push(
        ApiService.get("/api/surveys/" + this.id + "/organizationGroups").then(({ data }) =>
          this.$set(this.audience, "organizationGroups", data._embedded.organizationGroups)
        )
      );
      return Promise.all(promises).then(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      return this.saveAudience().then(() => {
        return this.fetchData().then(() => {
          this.$emit("input", this.audience);
          this.$emit("onLoad");
        });
      });
    },
    saveAudience() {
      let participantTypes = this.audience.participantTypes.map(participantType =>
        this.$api.getSelfUrl("participantType", participantType)
      );
      let participantGroups = this.audience.participantGroups.map(participantGroup =>
        this.$api.getSelfUrl("participantGroup", participantGroup)
      );
      let organizationTypes = this.audience.organizationTypes.map(organizationType =>
        this.$api.getSelfUrl("organizationType", organizationType)
      );
      let organizationGroups = this.audience.organizationGroups.map(organizationGroup =>
        this.$api.getSelfUrl("organizationGroup", organizationGroup)
      );

      let postForm = {
        participantTypes: participantTypes,
        participantGroups: participantGroups,
        organizationTypes: organizationTypes,
        organizationGroups: organizationGroups
      };
      return this.$api.patch("/api/surveys/" + this.$route.params.id, postForm);
    },
    fetchElibileParticipants() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let postForm = {
        organizationGroups: this.audience.organizationGroups,
        organizationTypes: this.audience.organizationTypes,
        participantGroups: this.audience.participantGroups,
        participantTypes: this.audience.participantTypes
      };
      ApiService.post(
        "/api/participants/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        postForm
      )
        .then(({ data }) => {
          this.eligibleParticipants = data.content;
          this.total = data.totalElements;
        })
        .then(() => {
          this.eligibleParticipants
            .reduce((prev, next) => {
              return prev.then(() => {
                if (next.organization) {
                  return ApiService.get("/api/organizations/" + next.organization.id + "/organizationGroups").then(
                    ({ data }) => {
                      this.$set(next, "organizationGroups", data._embedded.organizationGroups);
                    }
                  );
                } else {
                  return Promise.resolve();
                }
              });
            }, Promise.resolve())
            .then(() => {
              this.loading = false;
            });
        });
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetchData().then(() => {
        this.$emit("input", this.audience);
        this.$emit("onLoad");
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isNew() {
      return this.id == "0" || this.id == 0;
    },
    headers() {
      let baseHeaders = [
        { text: "Participant Key", value: "participantKey" },
        { text: "Participant Type", value: "participantType.name", sortable: false },
        { text: "Organization", value: "organizationName", sortable: false },
        { text: "Organization Type", value: "organizationType", sortable: false },
        { text: "Organization Group", value: "organizationGroups", sortable: false }
      ];
      if (this.showParticipantGroups) {
        baseHeaders.splice(2, 0, { text: "Participant Group", value: "participantGroup.name", sortable: false });
      }
      return baseHeaders;
    }
  },
  watch: {
    audience: {
      deep: true,
      handler() {
        if (!this.loading && !this.disabled) {
          this.fetchElibileParticipants();
        }
      }
    },
    options: {
      deep: true,
      handler() {
        if (!this.loading && !this.disabled) {
          this.fetchElibileParticipants();
        }
      }
    }
  }
};
</script>
