var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-pagination',{attrs:{"length":_vm.amountOfQuestions},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-divider',{staticClass:"mt-2 mb-2"}),_c('v-container',[_c('v-card',{staticClass:"pa-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[(_vm.questions && _vm.questions[_vm.page - 1])?_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[(_vm.questions && _vm.questions.length > 0)?_c('v-text-field',{staticClass:"title",attrs:{"label":"Question","disabled":_vm.disabled,"rules":[function (v) { return !!v || 'Required'; }]},model:{value:(_vm.questions[_vm.page - 1].description),callback:function ($$v) {_vm.$set(_vm.questions[_vm.page - 1], "description", $$v)},expression:"questions[page - 1].description"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"3"}},[_c('SurveyQuestionTypeField',{attrs:{"label":"Question Type","disabled":_vm.disabled},model:{value:(_vm.questions[_vm.page - 1].surveyQuestionType),callback:function ($$v) {_vm.$set(_vm.questions[_vm.page - 1], "surveyQuestionType", $$v)},expression:"questions[page - 1].surveyQuestionType"}})],1)],1):_vm._e(),(
                    _vm.questions &&
                      _vm.questions[_vm.page - 1] &&
                      _vm.questions[_vm.page - 1].surveyQuestionType &&
                      (_vm.questions[_vm.page - 1].surveyQuestionType.name == 'MULTIPLE_CHOICE' ||
                        _vm.questions[_vm.page - 1].surveyQuestionType.name == 'CHECK_BOXES')
                  )?_c('v-row',[_c('v-col',[_vm._l((_vm.questions[_vm.page - 1].options),function(option,index){return _c('v-text-field',{key:index,ref:'option' + index,refInFor:true,attrs:{"label":'Option ' + (index + 1),"disabled":_vm.disabled,"rules":[function (v) { return !!v || 'Required'; }]},on:{"blur":function($event){if (_vm.amountOfOptions > 1 && !_vm.addingNewOption) {
                          _vm.removeEmptyOption();
                        }},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.addingNewOption = true;
                        if (index == _vm.questions[_vm.page - 1].options.length - 1) {
                          _vm.addNewOption();
                        }}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"disabled":_vm.disabled,"color":"red"},on:{"click":function($event){if (_vm.amountOfOptions > 1) {
                              _vm.deleteOption(index);
                            }}}},[_vm._v("mdi-close")])]},proxy:true}],null,true),model:{value:(_vm.questions[_vm.page - 1].options[index].description),callback:function ($$v) {_vm.$set(_vm.questions[_vm.page - 1].options[index], "description", $$v)},expression:"questions[page - 1].options[index].description"}})}),_c('v-text-field',{staticClass:"newOptionTextField",staticStyle:{"cursor":"text"},attrs:{"placeholder":"New option","readonly":"","flat":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.addNewOption()}}})],2)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.questions[_vm.page - 1])?_c('v-switch',{staticClass:"mr-2 mt-n1",attrs:{"label":"Required","disabled":_vm.disabled,"dense":"","hide-details":true},model:{value:(_vm.questions[_vm.page - 1].required),callback:function ($$v) {_vm.$set(_vm.questions[_vm.page - 1], "required", $$v)},expression:"questions[page - 1].required"}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"primary","disabled":_vm.page == 1 || _vm.disabled},on:{"click":function($event){return _vm.moveQuestionLeft()}}},on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_vm._v(" Move left ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"primary","disabled":_vm.page == _vm.questions.length || _vm.disabled},on:{"click":function($event){return _vm.moveQuestionRight()}}},on),[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}])},[_vm._v(" Move right ")]),_c('v-divider',{staticClass:"ml-2 mr-2",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"success","disabled":_vm.disabled},on:{"click":_vm.addNewQuestion}},on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_vm._v(" Add new Question ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"warning","disabled":_vm.amountOfQuestions == 1 || _vm.disabled},on:{"click":function($event){_vm.showDeleteQuestionDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_vm._v(" Delete Question ")])],1)],1)],1)],1),_c('v-row')],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDeleteQuestionDialog),callback:function ($$v) {_vm.showDeleteQuestionDialog=$$v},expression:"showDeleteQuestionDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete Question ")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this question? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.showDeleteQuestionDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.deleteQuestion();
              _vm.showDeleteQuestionDialog = false;}}},[_vm._v("Delete")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }