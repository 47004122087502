<template>
  <v-form>
    <v-container class="pt-8">
      <v-row>
        <v-col cols="4">
          <KeyField label="Survey Key" dense v-model="details.surveyKey" :disabled="disabled"></KeyField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"
          ><v-text-field dense label="Name" v-model="details.name" :disabled="disabled"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8"
          ><v-textarea dense label="Description" v-model="details.description" :disabled="disabled"></v-textarea
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="4"
          ><v-text-field
            dense
            label="Completed Title"
            v-model="details.completedTitle"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8"
          ><v-textarea
            dense
            label="Completed Description"
            v-model="details.completedDescription"
            :disabled="disabled"
          ></v-textarea
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <DateTimePickerField
            v-model="details.effectiveDate"
            :disabled="disabled"
            :label="$i18n.translate('Effective Date')"
            defaultTime="00:00"
          ></DateTimePickerField>
        </v-col>
        <v-col cols="3">
          <DateTimePickerField
            v-model="details.expirationDate"
            :disabled="disabled"
            :label="$i18n.translate('Expiration Date')"
            defaultTime="23:59"
          ></DateTimePickerField>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";
import DateTimePickerField from "../fields/DateTimePickerField.vue";
import KeyField from "../fields/KeyField.vue";

export default {
  components: { KeyField, DateTimePickerField },
  name: "SurveyDetails",
  props: {
    value: Object,
    id: {
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    surveyQuestionTypes: [],
    errors: {},
    details: {}
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      return ApiService.get("/api/surveys/" + this.id).then(({ data }) => {
        this.$set(this, "details", data);
      });
    },
    onSubmit() {
      return this.saveDetails();
    },
    saveDetails() {
      let postForm = Object.assign({}, this.details);

      postForm.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      if (postForm.country) {
        postForm.country = { name: postForm.country.name };
      }

      let serviceCall = null;
      if (this.isNew) {
        serviceCall = this.$api.post("/api/surveys", postForm).then(({ data }) => {
          this.$emit("reload", data.id);
        });
      } else {
        delete postForm.program;
        delete postForm._links;
        delete postForm.updatedDate;
        delete postForm.createdDate;

        serviceCall = this.$api.patch("/api/surveys/" + this.id, postForm);
      }
      return serviceCall;
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetchData().then(() => {
        this.$emit("input", this.details);
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isNew() {
      return this.id == "0" || this.id == 0;
    }
  }
};
</script>
